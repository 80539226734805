import React, { useEffect } from 'react'
import RingLoader from "react-spinners/RingLoader";

export const Loading = (props) => {

    return (
        <header className="App-header">
            <RingLoader
                color="#ffffff"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </header>
    )
}
