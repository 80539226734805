import React, { useEffect} from 'react'
import { BigHead } from '@bigheads/core'
import { randomAvatar } from '../helpers'

export const RandomAvatar = (props) => {
    useEffect(() => {
        console.log(props.avatar)
    }, [])
    return (
        <div>
        <BigHead {...randomAvatar}
        />
        </div>
    )
}
