import React from 'react'
import { Table, Container } from 'reactstrap';
export const Champions = () => {
    return (
        <div className="roadRage text-white title-screen">
            <h1>Champions</h1>
        <Container>
            <Table className="text-white">
                <thead>
                <tr>
                    <th></th>
                    <th>Wrassler</th>
                    <th>Record</th>
                    <th>Score</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Jeff</td>
                    <td>14-2</td>
                    <td>2150</td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Kyle</td>
                    <td>10-6</td>
                    <td>1870</td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Mike</td>
                    <td>8-8</td>
                    <td>1610</td>
                </tr>
                </tbody>
            </Table>
            </Container>
        </div>
    )
}
