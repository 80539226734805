import React from 'react';
import Parse from 'parse';
import { initializeParse, useParseQuery } from  '@parse/react';

initializeParse(
  "https://wrasslin.b4a.io/",
  '7hmAmgxrtjyhpCC9umymyA1LXeuK8k81YEMQrvZf',
  'u4irFpoSwv97ApwrUI5Brn2vFqkjt73wuzjdQLLn'
);

export default function App() {
  //make sure your class is enabled for Real Time Notifications (Live Query) checking the menu -> App Settings -> Server Settings -> Server URL and Live Query
 const parseQuery = new Parse.Query('YOUR_CLASS_NAME_HERE');
 const {
    isLive,
    isLoading,
    isSyncing,
    results,
    count,
    error,
    reload
  } = useParseQuery(parseQuery);

  return (
    <div>
      {isLoading && (
        <p>Loading...</p>
      )}
      {isLive && (
        <p>Live!</p>
      )}
      {isSyncing && (
        <p>Syncing...</p>
      )}
      {results && (
        <ul>
          {results.map(result => (
            <li key={result.id}>
              {result.get('CLASS_COLUMN_NAME_HERE')}
            </li>
          ))}
        </ul>
      )}
      <p>{count}</p>
      {error && (
        <p>{error.message}</p>
      )}
      <button
        onClick={reload}
      >
        Reload
      </button>
    </div>
  );
}