// context.jsx

// Import createContext() method from React:
import { createContext, useState } from 'react'
import Parse from 'parse/dist/parse.min.js';
// Create new context:
export const authContext = createContext()

// Create new provider component:
export const AuthProvider = (props) => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [user, setUser] = useState({})
    const [avatar, setAvatar] = useState({})
    // Prepare values to share:
    const val = {
        loggedIn, // The state itself
        setLoggedIn, // The state update function
        user,
        setUser,
        avatar,
        setAvatar
    }
  return (
    <authContext.Provider value={val}>
      {props.children}
    </authContext.Provider>
  )
}