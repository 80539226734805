import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row, Card, CardTitle, CardBody, Button } from 'reactstrap';
import { AvatarCreator } from './AvatarCreator'
import { UserAvatar } from './UserAvatar';
import { getMyInfo, getYourFights } from '../helpers'
import { authContext } from '../context'
import { OfficialMatch } from '../OfficialMatch';
export const Profile = (props) => {

    const [avatarEdit, setAvatarEdit] = useState(false)
    const [fights, setFights] = useState([])
    const [myInfo, setMyInfo] = useState({})
    const [fightMode, setFightMode] = useState(false)
    const { user, avatar } = useContext(authContext)
    const handleSave = () => {
        setAvatarEdit(false)
    }
    const getInfo = async() => {
        const myFights = await getYourFights(user)
        const res = await getMyInfo()
        await setMyInfo(res)
        await setFights(myFights)
    }

    const [setup, setSetup] = useState({
        challengee: '',
        events: []
    })
    const [mode, setMode] = useState('profile')
    useEffect(() => {
        getInfo()
    }, [])
    const doFight = async (challengee, events) => {
        await setSetup({...setup, challengee: challengee, events: events})
        setMode('fight')
    }
    return (
        <div className="vh-100">
          { mode === 'fight' && <OfficialMatch             
            queries={props.queries} 
            user={user} 
            avatar={avatar}
            playerOne={user.attributes}
            playerTwo={setup.challengee}
            matchEvents={setup.matchEvents} />
            }
        { myInfo !== {} &&
            !avatarEdit ?
          <Container className={`${mode === 'fight' && 'd-none'}`}>
            <Row className="title-screen">
              <Col md="6" lg="8" xl="10" className="mt-5">
                <Card style={{ borderRadius: '15px' }}>
                  <CardBody className="p-4">
                    <div className={`${!props.queries.isMobile ? 'd-flex' : ''} text-black`}>
                      <div className="avatarContainer my-auto">
                          <UserAvatar setAvatarEdit={setAvatarEdit} avatar={avatar} className='profileAvatar'/>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <CardTitle><h2>{props.user.get("username")}</h2></CardTitle>
    
                        <Row className="d-flex justify-content-start rounded-3 p-2 mb-2"
                          style={{ backgroundColor: '#efefef' }}>
                          <Col>
                            <p className="small text-muted mb-1">Wins</p>
                            <p className="mb-0">{user.attributes.wins}</p>
                          </Col>
                          <Col className="px-3">
                            <p className="small text-muted mb-1">Losses</p>
                            <p className="mb-0">{user.attributes.losses}</p>
                          </Col>
                          <Col>
                            <p className="small text-muted mb-1">Friends</p>
                            <p className="mb-0">{user.attributes.friends ? user.attributes.friends.length : 0}</p>
                          </Col>
                        </Row>
                        <div className="align-items-center pt-1">
                          <h3>Pending bouts</h3>
                          {fights && fights.map(fight => {
                              return (
                                <Row className="d-flex justify-content-start align-items-center rounded-3 p-2 mb-2"
                                style={{ backgroundColor: '#efefef' }}>
                                    <Col>
                                        <h5 className='mb-0'>{fight.attributes.challengeeName}</h5>
                                    </Col>
                                    <Col>
                                        <Button color='success' onClick={() => doFight(fight.attributes.challengee, fight.attributes.events)}>ACCEPT</Button>
                                    </Col>
                                    <Col>
                                        <Button color='danger'>REJECT</Button>
                                    </Col>
                                </Row>
                                )
                          })}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          :
          <AvatarCreator user={myInfo} handleSave={handleSave} queries={props.queries}/>
            }
        </div>
      );
}
