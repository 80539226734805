import React, { useState, useEffect } from 'react'
import { motion } from "framer-motion"
import { sounds } from './helpers'
import useSound from 'use-sound';
export const Pin = (props) => {
    
    const [pinCount, setPinCount] = useState(0)
    const [secondWind, setSecondWind] = useState(false);
    const [playWild] = useSound(sounds.wild, { volume: 0.25 })
    const [playOne] = useSound(sounds.one)
    const [playTwo] = useSound(sounds.two)
    const [playThree] = useSound(sounds.three)
    const [playBell] = useSound(sounds.bell, { volume: 0.5 });
    const [playPinTheme, { stop }] = useSound(sounds.pinTheme, { volume: 0.5, interrupt: true })
    const [playMatchOver, stopMatchOver] = useSound(sounds.matchOver, { volume: 0.5, interrupt: true })
    useEffect(() => {
        pinCount === 1 && playPinTheme();
        pinCount === 1 && playOne();
    }, [pinCount])

    const pinnedGuy = props.playerOneHealth < props.playerTwoHealth ? props.playerOne.name : props.playerTwo.name;

    useEffect(() => {
        console.log(props.event)
        console.log(props.playerTwoHealth)
        console.log(pinnedGuy)
        if (pinCount === 0){
            setTimeout(() =>
            {
                setPinCount(1)
            }, 50)
        }

        pinCount === 2 && playTwo();
        if (pinCount === 3) {
            playThree()
            playBell();
            playWild();
            setTimeout(() => {
                setPinCount((prevCount) => prevCount + 1)
                playMatchOver();
            }, 1500)
        }
        const interval = setTimeout(() => {
          if (pinCount < 3 && !secondWind) {
              if(props.hasSecondWind) {
                stop();
                props.secondWind();
                setSecondWind(true);
                setTimeout(() => {
                    setSecondWind(false)
                }, 2000)
              } else {
                setPinCount((prevCount) => prevCount + 1)
              }
          }
        }, 1500);
        return () => {
            clearTimeout(interval);
        };
      }, [pinCount]);
      const restartMatch = () => {
          props.reset();
          stopMatchOver.stop();
      }
    return (
        <div>
            { !secondWind ?
            <div>
            <motion.div 
                animate={{
                    x: [0, 15, 0, 15, 0, 15, 0]
                }}
                transition={{
                    duration: .2,
                }}
                className="title-screen">
                    {pinCount <= 3 ?
                    <h1 className='winner'>{pinCount}</h1>
                    :
                    <div>
                    <h1 className='winner'>{`${props.event.sentence}`}</h1>
                    <motion.h2 animate={{
                    opacity: [0, 1]
                }}
                transition={{
                    duration: 0.2,
                    delay: 2
                }} className='winner roadRage playAgain' onClick={restartMatch}>Play again?</motion.h2>
                </div>
            }
            </motion.div>


            </div>
            :
            <motion.div 
            animate={{
                x: [0, 15, 0, 15, 0, 15, 0]
            }}
            transition={{
                duration: 0.2,
            }}
            className="title-screen">
                <h1 className='second-wind'>{`${props.event.sentence}`}</h1>
            </motion.div>
            }
        </div>
    )
}
