import { useState, useEffect } from 'react';
import { QuickMatch } from './QuickMatch';
import { UseAudio } from './UseAudio'

export const AssetLoader = (props) => {
    const [audioElements, setAudioElements] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const _sounds = Object.keys(props.sounds).map((obj, key) => UseAudio(key));

        const allLoading = _sounds.map(s => s[1]).every(el => el === false);
        setAudioElements(_sounds.reduce((acc, [audio, loading]) => {
            acc = audio;
            return acc;
        }, {}));
        if(allLoading) {
            setLoading(false);
        }
    }, [props.sounds]);

    if (loading) {
        return <div>Loading sounds...</div>;
    } else {
        return <QuickMatch queries={props.queries} user={props.user} avatar={props.avatar} audioElements={audioElements}/>
    }
}
