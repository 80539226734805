import React, { useState, useEffect, useContext } from 'react'
import { BigHead } from '@bigheads/core'
import { authContext } from '../context'
import { Dropdown, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Select, Input, Label, FormGroup, Row, Button, Col } from 'reactstrap';
import { accessories, bodies, clothing, clothingColors, eyebrows, eyes, facialHairs, graphics, hairs, hairColors, hats, hatColors, lipColors, mouths, skinTones, getMyInfo } from '../helpers'
import Parse from 'parse/dist/parse.min.js';


export const AvatarCreator = (props) => {
    const currentAvatar = props.user.attributes.avatar
    const [myAvatar, setMyAvatar] = useState(currentAvatar)
    const {avatar, setAvatar} = useContext(authContext)
    const [split, setSplit] = useState(false)
    const onChange = e => {
        setMyAvatar({ ...myAvatar, [e.target.name]: e.target.value })
    }

    const saveAvatar = async () => {
        console.log(props.user)
        try {
            let userInfo = new Parse.Object('UserInfo')

            userInfo.set('objectId', props.user.id)
            userInfo.set('avatar', myAvatar)

            await userInfo.save()
            setAvatar(myAvatar)
          } catch (error) {
            console.log('Error saving new person: ', error);
          }
          props.handleSave();
    }

    const setSize = () => {
        if (props.queries.isDesktopOrLaptop || props.queries.isBigScreen) {
            setSplit(false)
        } else if (props.queries.isTabletOrMobile){
            setSplit(true)
        }
    }
    useEffect(setSize, [split])
    const renderDropdown = (name, arr, attributeName) => {
        return(
        <div>
          <FormGroup>
            <Label for={name} className='text-white text-bold mb-0'><h5>{name}</h5></Label>
            <Input
            id="exampleSelect"
            name={`${attributeName}`}
            type="select"
            onChange={onChange}
            >
                {arr.map(item => {
                    if(avatar[`${attributeName}`] === item) {
                        return <option selected>{item}</option>
                    } else {
                        return <option>{item}</option>
                    }
                })}
            </Input>
        </FormGroup>
        </div>
        )
    }
        return (
        <div>
            <Row>
                {props.queries.isMobile ?
            <div>
            <Col>
            <BigHead
                accessory={myAvatar.accessory}
                body={myAvatar.body}
                circleColor={myAvatar.circleColor}
                clothing={myAvatar.clothing}
                clothingColor={myAvatar.clothingColor}
                eyebrows={myAvatar.eyebrows}
                eyes={myAvatar.eyes}
                facialHair={myAvatar.facialHair}
                graphic={myAvatar.graphic}
                hair={myAvatar.hair}
                hairColor={myAvatar.hairColor}
                hat={myAvatar.hat}
                hatColor={myAvatar.hatColor}
                lashes={myAvatar.lashes}
                lipColor={myAvatar.lipColor}
                mask={myAvatar.mask}
                faceMask={myAvatar.faceMask}
                mouth={myAvatar.mouth}
                skinTone={myAvatar.skinTone}
                style={{ width: '300px'}}
            />
            </Col>
            <Col className='avatarOptions'>
                <div>{renderDropdown('Skin tone', skinTones, 'skinTone')}</div>
                <div>{renderDropdown('Body', bodies, 'body')}</div>
                <div>{renderDropdown('Clothing', clothing, 'clothing')}</div>
                <div>{renderDropdown('Clothing color', clothingColors, 'clothingColor')}</div>
                <div>{renderDropdown('Eyes', eyes, 'eyes')}</div>
                <div>{renderDropdown('Eyebrows', eyebrows, 'eyebrows')}</div>
                <div>{renderDropdown('Facial hair', facialHairs, 'facialHair')}</div>
                <div>{renderDropdown('Graphic', graphics, 'graphic')}</div>
                <div>{renderDropdown('Hair', hairs, 'hair')}</div>
                <div>{renderDropdown('Hair color', hairColors, 'hairColor')}</div>
                <div>{renderDropdown('Hat', hats, 'hat')}</div>
                <div>{renderDropdown('Hat color', hatColors, 'hatColor')}</div>
                <div/>
                <div>{renderDropdown('Lip color', lipColors, 'lipColor')}</div>
                <div>{renderDropdown('Mouth', mouths, 'mouth')}</div>
                <div/>
            </Col>
            </div>
            :
            <div>
            <Col>
            <BigHead
                accessory={myAvatar.accessory}
                body={myAvatar.body}
                circleColor={myAvatar.circleColor}
                clothing={myAvatar.clothing}
                clothingColor={myAvatar.clothingColor}
                eyebrows={myAvatar.eyebrows}
                eyes={myAvatar.eyes}
                facialHair={myAvatar.facialHair}
                graphic={myAvatar.graphic}
                hair={myAvatar.hair}
                hairColor={myAvatar.hairColor}
                hat={myAvatar.hat}
                hatColor={myAvatar.hatColor}
                lashes={myAvatar.lashes}
                lipColor={myAvatar.lipColor}
                mask={myAvatar.mask}
                faceMask={myAvatar.faceMask}
                mouth={myAvatar.mouth}
                skinTone={myAvatar.skinTone}
                style={{ width: '300px'}}
            />
            </Col>
            <Col>
            <Row>
                <Col>{renderDropdown('Skin tone', skinTones, 'skinTone')}</Col>
                <Col>{renderDropdown('Body', bodies, 'body')}</Col>
                <Col>{renderDropdown('Clothing', clothing, 'clothing')}</Col>
                <Col>{renderDropdown('Clothing color', clothingColors, 'clothingColor')}</Col>
            </Row>
            <Row>
                <Col>{renderDropdown('Eyes', eyes, 'eyes')}</Col>
                <Col>{renderDropdown('Eyebrows', eyebrows, 'eyebrows')}</Col>
                <Col>{renderDropdown('Facial hair', facialHairs, 'facialHair')}</Col>
                <Col>{renderDropdown('Graphic', graphics, 'graphic')}</Col>
            </Row>
            <Row>
                <Col>{renderDropdown('Hair', hairs, 'hair')}</Col>
                <Col>{renderDropdown('Hair color', hairColors, 'hairColor')}</Col>
                <Col>{renderDropdown('Hat', hats, 'hat')}</Col>
                <Col>{renderDropdown('Hat color', hatColors, 'hatColor')}</Col>
            </Row>
            <Row>
                <Col/>
                <Col>{renderDropdown('Lip color', lipColors, 'lipColor')}</Col>
                <Col>{renderDropdown('Mouth', mouths, 'mouth')}</Col>
                <Col/>
            </Row>
            </Col>
            </div>
}
            </Row>
            <Button color='primary' onClick={saveAvatar}>SAVE</Button>
        </div>
    )
}
