import { useState, useEffect } from 'react';
export const UseAudio = (url) => {
    const [audio] = useState(new Audio(url));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        audio.addEventListener('canplay', () => {
            setLoading(false);
        });
        audio.load();
        return () => {
            audio.removeEventListener('canplay');
        };
    }, []);
    return [audio, loading];
}

