import React from 'react'
import crowd from './sounds/crowd.mp3'
import bell from './sounds/bell.mp3';
import wild from './sounds/wild.mp3';
import austinTheme from './sounds/stonecold.mp3';
import kaneTheme from './sounds/kane.mp3';
import underTakerTheme from './sounds/undertaker.mp3';
import hhhTheme from './sounds/hhh.mp3';
import woo from './sounds/woo.mp3';
import punch from './sounds/punch7.mp3'
import punch1 from './sounds/punch1.mp3'
import punch2 from './sounds/punch2.mp3'
import punch3 from './sounds/punch3.mp3'
import punch4 from './sounds/punch4.mp3'
import punch5 from './sounds/punch5.mp3'
import punch6 from './sounds/punch6.mp3'
import useSound from 'use-sound';
import { sounds } from './helpers'
export const Sounds = () => {
    const [playCrowd] = useSound(sounds.crowd, { volume: 1 });
    const [playBell] = useSound(sounds.bell, { volume: 0.5 });
    const [playWild] = useSound(sounds.wild, { volume: 1 })
    const [playAustinTheme] = useSound(sounds.austinTheme);
    const [playKaneTheme] = useSound(sounds.kaneTheme);
    const [playUnderTakerTheme] = useSound(sounds.underTakerTheme);
    const [playHhhTheme] = useSound(sounds.hhhTheme);
    const [playWoo] = useSound(sounds.woo)
    const [playMainTheme, stopMainTheme] = useSound(sounds.mainTheme, { volume: 0.5, interrupt: true })
    const [playPinTheme] = useSound(sounds.pinTheme)
    const [playPumped] = useSound(sounds.pumpedSound)
    const [playMatchOver] = useSound(sounds.matchOver)
    const [playPunch] = useSound(sounds.punch, { volume: 0.25 })
    const [playPunch1] = useSound(sounds.punch1, { volume: 0.25 })
    const [playPunch2] = useSound(sounds.punch2, { volume: 0.25 })
    const [playPunch3] = useSound(sounds.punch3, { volume: 0.25 })
    const [playPunch4] = useSound(sounds.punch4, { volume: 0.25 })
    const [playPunch5] = useSound(sounds.punch5, { volume: 0.25 })
    const [playPunch6] = useSound(sounds.punch6, { volume: 0.25 })
    
    const punches = [playPunch, playPunch1, playPunch2, playPunch3, playPunch4, playPunch5, playPunch6]
    const wweThemes = {
        austin: playAustinTheme,
        kane: playKaneTheme,
        undertaker: playUnderTakerTheme,
        hhh: playHhhTheme
    }
    return { punches, wweThemes, playCrowd, playWild, playBell, playPumped, playMainTheme, stopMainTheme }
}

export const TestSound = () => {
    const [testSound] = useSound(kaneTheme)
    return { testSound }
}
