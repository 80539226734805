import React, { useState, useContext } from 'react';
import Parse from 'parse/dist/parse.min.js';
import '../../App.css';
import { Button, Input, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { authContext } from '../../context'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { getMyInfo, getUserAvatar } from '../../helpers'

  // Function that will return current user and also update current username
  export const getCurrentUser = async function () {
    const currentUser = await Parse.User.current();
    console.log(currentUser)
    return currentUser;
  }; 
export const doUserLogOut = async function () {
    try {
      await Parse.User.logOut();
      // To verify that current user is now empty, currentAsync can be used
      const currentUser = await Parse.User.current();
      // Update state variable holding current user
      return true;
    } catch (error) {
      alert(`Error! ${error.message}`);
      return false;
    }
  };
export const UserLogin = (props) => {
  // State variables
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { loggedIn, setLoggedIn, user, setUser, avatar, setAvatar } = useContext(authContext)


  const doUserLogIn = async function () {
    // Note that these values come from state variables that we've declared before
    const usernameValue = username;
    const passwordValue = password;
    try {
      const loggedInUser = await Parse.User.logIn(usernameValue, passwordValue);
      // logIn returns the corresponding ParseUser object
      alert(
        `Success! User ${loggedInUser.get(
          'username'
        )} has successfully signed in!`
      );
      // To verify that this is in fact the current user, `current` can be used
      const currentUser = await Parse.User.current();
      if(loggedInUser === currentUser) {
        await props.getUser()
       } else {
         setLoggedIn(false);
       }
      props.reset();
      return true;
    } catch (error) {
      // Error can be caused by wrong parameters or lack of Internet connection
      alert(`Error! ${error.message}`);
      return false;
    }
  };
  const setUserAvatar = async function () {
    const userAvatar = await getUserAvatar()
    setAvatar(userAvatar)
  };
  return (
    <div>
      <div className="header">
        <h2 className="roadRage">{'Log In'}</h2>
      </div>
      {!loggedIn &&
      <div className="container">
      <Col className="form-group">
            <Row>
                <label for="username" class="roadRage">Username:</label>
                <Input
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    placeholder="Username"
                    name="username"
                    className="form_input mb-3"
                />
            </Row>
             <Row>
            <label for="password" class="roadRage">Password:</label>
            <Input
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Password"
                type="password"
                className="form_input"
            />
            </Row>
        </Col>
        <div className="form_buttons">
          <Button color='primary mb-4 mt-4' onClick={() => doUserLogIn()}>Log In</Button>
        </div>
        <Row>
            {/* <Col>
            <FontAwesomeIcon icon={faFacebook} />
            </Col>
            <Col>
            <FontAwesomeIcon icon={faGoogle} />
            </Col> */}
                
                
            {/* <img src={'https://findicons.com/files/icons/2830/clean_social_icons/250/facebook.png'} alt=""/> */}
        </Row>
        <p className="form__hint">Don't have an account? <Button color='primary mb-4 mt-4' onClick={props.switchAuth}>Sign up</Button></p>
      </div>
        }
        {loggedIn &&
            <div className="form_buttons">
            <Button color='primary mb-4 mt-4' onClick={() => doUserLogOut()}>Log Out</Button>
          </div>
        }
    </div>
  );
};