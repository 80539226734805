import React, { useState, useEffect, useContext } from 'react'
import { Button, Alert, Input, Col, Row, Card, Container, CardBody } from 'reactstrap';
import { authContext } from './context'
import { motion } from "framer-motion"
import { Registration } from './components/auth/Registration'
import { UserLogin } from './components/auth/UserLogIn'
export const Header = (props) => {
    const [alertOpen, setAlertOpen] = useState(false);
    const [matchType, setMatchType] = useState(props.matchType)
    const [auth, setAuth] = useState(props.auth)
    const { loggedIn, setLoggedIn } = useContext(authContext)
    const [alertMsg, setAlertMsg] = useState("Enter names to wrassle")
    const showAlert = () => {
        if (!props.playerOne.name || !props.playerTwo.name) {
            setAlertMsg("Enter names to wrassle")
        } else if (props.playerOne.name == props.playerTwo.name) {
            setAlertMsg("Whoa whoa, no mirror matches here")
        }
        setAlertOpen(true);
        setTimeout(() => {
            setAlertOpen(false);
        }, 2000);
    }

    const chooseType = () => {
        return(
            <div>
                <h2 className='roadRage'>Choose Match Type</h2>
                <Row>
                    <Col>
                        <Card className='text-white match-type mb-3' onClick={() => setMatchType('official')}>
                            <CardBody>Official Match</CardBody>
                        </Card>

                        <Card className='text-white match-type' onClick={() => setMatchType('quick')}>
                            <CardBody>Quick Match</CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

    const quickMatch = () => {
        return (
            <div className={`${props.matchPhase > props.phases.setup && 'd-none'} ${props.matchPhase === props.phases.transition && 'upAndOut'}`}>
                <div id="enternames" className='mb-4 d-flex flex-column justify-content-center'>
                    <motion.div
                        animate={{
                        x: [ -1000, 0],
                        scale: [1, 1.5, 1],
                        rotate: [0, 360],
                        delay: 0.5
                        }}
                    >
                    <h2 className='mb-2 text-white roadRage'>Enter Player Names</h2>
                    </motion.div>
                    <Row className='justify-content-center'>
                        <Col lg={6}>
                            <Input id="p1" className={`mb-2 playerInput ${props.matchPhase === props.phases.transition && 'left'}`}  onChange={props.setPlayerOneName} placeholder="Player One" />
                        </Col>
                        <Col lg={6}>
                            <Input id="p2" className={`mb-2 playerInput ${props.matchPhase === props.phases.transition && 'right'}`} onChange={props.setPlayerTwoName} placeholder="Player Two" />
                        </Col>
                    </Row>
                </div>
                <Button color='primary mb-4' onClick={(!props.playerOne.name && !props.playerTwo.name) || (props.playerOne.name == props.playerTwo.name) ? showAlert : props.startMatch}>Start Match</Button>
                <Alert isOpen={alertOpen} color='danger'>{alertMsg}</Alert>
                </div>
        )
    }

    const officialMatch = () => {
        return (
            <div className={`${props.matchPhase > props.phases.setup && 'd-none'} ${props.matchPhase === props.phases.transition && 'upAndOut'}`}>
                <div id="enternames" className='mb-4 d-flex flex-column justify-content-center'>
                    <motion.div
                        animate={{
                        x: [ -1000, 0],
                        scale: [1, 1.5, 1],
                        rotate: [0, 360],
                        delay: 0.5
                        }}
                    >
                    <h2 className='mb-2 text-white roadRage'>Select Opponent</h2>
                    </motion.div>
                    <Row className='justify-content-center'>
                        <Input id="p1" className={`mb-2 playerInput d-none ${props.matchPhase === props.phases.transition && 'left'}`}  onChange={props.setPlayerOneName} placeholder="Player One" />

                        <Input id="p2" className={`mb-2 playerInput ${props.matchPhase === props.phases.transition && 'right'}`} onChange={props.setPlayerTwoName} placeholder="Player Two" />
                    </Row>
                </div>
                <Button color='primary mb-4' onClick={(!props.playerOne.name && !props.playerTwo.name) ? showAlert : props.startMatch}>Start Match</Button>
                <Alert isOpen={alertOpen} color='danger'>{alertMsg}</Alert>
                </div>
        )
    }
    const setMatchBlank = () => setMatchType('')
    const showAuth = () => {
        if (auth === 'login') {
            return <UserLogin reset={setMatchBlank} getUser={props.getUser} switchAuth={() => setAuth('register')}/>
        } else {
            return <Registration reset={setMatchBlank} getUser={props.getUser} switchAuth={() => setAuth('login')}/>
        }
    }
    const showMode = () => {
        if (matchType === 'quick') {
            return quickMatch()
        } else if (matchType === 'official') {
            if (loggedIn) {
                return officialMatch()
            } else {
                return showAuth();
            }
        } else {
            return chooseType()
        }
    }
    return (
        <div className={`${props.matchPhase > props.phases.setup && 'd-none'} ${props.matchPhase === props.phases.transition && 'upAndOut'}`}>
        <div className="title-screen">
        <div className="title-container mb-5">
        <div className="lines"></div>
        <motion.h1 
            className='retro'
            animate={{
                y: [-2000, 0],
                scale: [1, 1.2, 1],
                }}
                transition={{
                    duration: 1.5,
                    ease: [0.5, 0.71, 1, 1.25],
                    delay: 0.075
                }}>
            <span>WRASSLIN'</span>
            <span>WRASSLIN'</span>
        </motion.h1>
        <motion.h2 
            className='retro'
            animate={{
                opacity: [0, 0, 0, 0, 0.5, 0.75, 1]
            }}
            transition={{
                duration: 3,
                delay: 0.35,
                ease: [0.5, 0.71, 1, 1.5],
            }}>Simulator</motion.h2>
        </div>
            { showMode() }
        </div>
        </div>
    )
}
