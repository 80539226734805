import React, {useState, useEffect} from 'react'
import { BigHead } from '@bigheads/core'


export const UserAvatar = (props) => {
    useEffect(() => {
        console.log(props.avatar)
    }, [])
    return (
        <div onClick={props.setAvatarEdit} className='profileAvatar'>
        <BigHead {...props.avatar}
        />
        </div>
    )
}
