import React, { useEffect, useState, useRef, Fragment, useContext } from 'react'
import { Col, Row, Container } from 'reactstrap';
import { shuffle, wwe, verbs, moves, determineFlash, sounds, phases, randEvent, determineShake, determineEyes, determineMouth, determineEyeBrows, warrior, randomAvatar } from './helpers'
import { Header } from './Header'
import { Pin } from './Pin'
import Parse from 'parse/dist/parse.min.js';
import ProgressBar from 'react-bootstrap/ProgressBar'
import useSound from 'use-sound';
import { authContext } from './context'
import { motion, useAnimation, AnimatePresence, useSpring, Variants } from "framer-motion"
import { BigHead } from '@bigheads/core'
import { UserAvatar } from './components/UserAvatar'
import { Sounds } from './Sounds'
import { matchEvents } from './helpers/fighthelpers';
export const Main = (props) => {
    const { punches, wweThemes, playCrowd, playWild, playBell, playPumped, playMainTheme, stopMainTheme } = Sounds() 
    const [myAvatar, setMyAvatar] = useState({})

    useEffect(() => {
        if(props.avatar){
        if(Object.keys(props.avatar).length !== 0) {
            const res = props.avatar
            setMyAvatar(res)
        }
       }
    }, [])
    const playerOneDamage = useRef(null);
    playerOneDamage.current = (damage) => {
      setPlayerOne({...playerOne,
        health: playerOne.health - damage > 0 ? playerOne.health - damage : 0,
        shake: determineShake(damage),
        pumped: damage < 0 && 'pumped',
        mouth: damage > 0 ? 'sad' : determineMouth(playerOneHealth.current)
        });
    };

    const playerTwoDamage = useRef(null);
    playerTwoDamage.current = (damage) => {
      setPlayerTwo({...playerTwo,
        health: playerTwo.health - damage > 0 ? playerTwo.health - damage : 0,
        shake: determineShake(damage),
        pumped: damage < 0 && 'pumped',
        mouth: damage > 0 ? 'sad' : determineMouth(playerTwoHealth.current)
    });
    };
    const clearPlayerOneAnim = useRef(null);
    clearPlayerOneAnim.current = () => {
        setPlayerOne({...playerOne,
          shake: '',
          pumped: '',
          mouth: determineMouth(playerOneHealth.current)
      });
      };  
    const clearPlayerTwoAnim = useRef(null);
    clearPlayerTwoAnim.current = () => {
        setPlayerTwo({...playerTwo,
          shake: '',
          pumped: '',
          mouth: determineMouth(playerTwoHealth.current)
      });
      };     

    const currentMove = useRef({
        sentence: '',
        image: '',
        damage: 0,
        recipient: '',
        attacker: ''
    });
    const changeCurrentMove = (event) => {
        currentMove.current = {
            sentence: event.sentence,
            image: event.img,
            damage: event.health,
            recipient: event.recipient,
            attacker: event.attacker
        }
    }

    const [eventArr, setEventArr] = useState([])
    const [playerOne, setPlayerOne] = useState({
        name: '',
        health: 100,
        shake: '',
        pumped: '',
        mouth: 'serious',
        avatar: {}
    });

    const [playerTwo, setPlayerTwo] = useState({
        name: '',
        health: 100,
        shake: '',
        pumped: '',
        mouth: 'serious',
        avatar: {}
    });

    const playerOneHealth = useRef(playerOne.health)
    const playerTwoHealth = useRef(playerTwo.health)
    useEffect(() => {
        moves.forEach(move => {
            const img = new Image();
            img.src = move.pic
        })

        setupPlayers();
    }, [])
    
    const setupPlayers = async () => {
        if (!props.playerOneProp && !props.playerTwoProp){
            await setPlayerOne({ ...playerOne, avatar: randomAvatar()})
            await setPlayerTwo({ ...playerTwo, avatar: randomAvatar()})
            } else {
            await setPlayerOne({ ...playerOne, name: props.playerOneProp.name, avatar: props.playerOneProp.avatar})
            await setPlayerTwo({ ...playerTwo, name: props.playerTwoProp.name, avatar: props.playerTwoProp.avatar})
            startMatch()      
            }
        // if (props.playerOneProp){
        //         await setPlayerOne({ ...playerOne, name: props.playerOneProp.name, avatar: props.playerOneProp.avatar})
        //     } else {
        //         await setPlayerOne({ ...playerOne, avatar: randomAvatar()})    
        //     }
        // if (props.playerTwoProp){
        //         await setPlayerOne({ ...playerTwo, name: props.playerTwoProp.name, avatar: props.playerTwoProp.avatar})
        //     } else {
        //         await setPlayerOne({ ...playerTwo, avatar: randomAvatar()})    
        //     }
        //     console.log(props.playerOneProp)
        //     console.log(props.playerTwoProp)
        //     startMatch()      
    }
    const [matchPhase, setMatchPhase] = useState(phases.setup)

    const [startMoves, setStartMoves] = useState(false)
    const setPlayerOneName = async (e) => {
        await setPlayerOne({ ...playerOne, name: e.target.value.toUpperCase()})
    };

    const setPlayerTwoName = async (e) => {
        await setPlayerTwo({ ...playerTwo, name: e.target.value.toUpperCase()})
    };

    const makeShakeHappen = () => {
        return determineShake(currentMove.damage)
    }

    useEffect(() => {
        if(matchPhase === phases.matchStart){
            mainFight()
        }
        }, [matchPhase])

    const battle = async () => {
        let events;
        if (!props.matchEvents) {
         events = await matchEvents(playerOne, playerOneHealth.current, playerTwo, playerTwoHealth.current)
        } else {
            events = props.matchEvents
        }
        await setEventArr(events)
    }
    const [hasSecondWind, setHasSecondWind] = useState(false)
    const reset = async() => {
        await changeCurrentMove({})
        await setEventArr([])
        setPlayerOneName('')
        setPlayerTwoName('')
        playerOneHealth.current = 100
        playerTwoHealth.current = 100
        setMatchPhase(phases.setup)
    }
    const i = useRef(0)
    const mainFight = async () => {
        clearPlayerOneAnim.current()
        clearPlayerTwoAnim.current()

        setTimeout(() => {
            playMainTheme();
            setStartMoves(true)
        }, 2000)
        if (eventArr.length > 0 && i.current < eventArr.length) {
            const interval = setInterval(() => {
                const event = eventArr[i.current];
                changeCurrentMove(event)
                if (event.type === 'move') {
                setTimeout(() => {
                    if (event.recipient.name === playerOne.name){
                        playerOneHealth.current = Math.max(event.playerOneHealth, 0);
                        playerOneDamage.current(event.health)
                    } else if (event.recipient.name === playerTwo.name){
                        playerTwoHealth.current = Math.max(event.playerTwoHealth, 0);
                        playerTwoDamage.current(event.health)
                    }
                    }, 300);

                    i.current++;
                    setTimeout(() => {
                        playerOneDamage.current(0);
                        playerTwoDamage.current(0);
                    }, 1000);
            } else {

                i.current++
                clearInterval(interval)
                setStartMoves(false)
                setHasSecondWind(event.type === 'secondWind' ? true : false)
                setTimeout(setMatchPhase(phases.pin))
                stopMainTheme.stop()
                if(event.type === 'pin'){
                    i.current=0;
                }
            }
            }, 2000)
        } else {
            setMatchPhase(phases.pin)
        }
    }

    const moveVariants = {
        hidden: { x: -2000, opacity: 0 },
        visible: {
            x: [-2000, 0, 0, 0, 0, 2000],
            opacity: [0, 1, 1, 1, 1, 0],
            transition: { duration: 2, repeat: Infinity }
        }
    }

    const imageVariants = {
        visible: {
            x: [-2.5, 0, -1.5, 0, 3.5, -2, 1, 0.5, -4.5, 3], y: [-2.5, 0, -1.5, 0, 3.5, -2, 1, 0.5, -4.5, 3],
            transition: { duration: 0.2, delay: 0.5, repeat: Infinity }
        }
    }

    const textVariants = {
        visible: {
            scale: [0, 0, 1.2, 1, 1, 1, 1, 1.2, 0, 0],
            transition: { duration: 2, repeat: Infinity, onAnimationIteration: () => console.log('0') }
        }
    }

    const renderMoveImg = (event) => {
        return(
        <AnimatePresence>
            <div className='fight-event w-100'>
                <motion.div
            variants={moveVariants}
            initial="hidden"
                animate="visible"
                className={`${props.queries.isMobile && 'mt-5'}`}
                >
                <motion.img
                    variants={imageVariants}
                    src={`${event.image}`} 
                    className='movepicbig'/>
                </motion.div>
            </div>
        </AnimatePresence>
        )
    }

    const onAnimationCentered = (latest) => {
        if (latest.x == 0) {
            console.log(latest)
            currentMove.current.damage > 0 ? randEvent(punches)() : playPumped();
        }
        if (latest.x == 2000) {

        }
    }
    const renderMove = (event) => {
        return(
            <div className='fight-event w-100'>
                <motion.div
            variants={moveVariants}
            onUpdate={(latest) => onAnimationCentered(latest)}
            initial="hidden"
                animate="visible"
                className={`${props.queries.isMobile && 'mt-5'}`}
                >
                <motion.img
                    variants={imageVariants}
                    src={`${event.image}`} 
                    className='movepic'/>
                <motion.h2 variants={textVariants} animate="visible" className="roadRage mt-2">{event.sentence}</motion.h2>
                </motion.div>

            </div>
        )
    }
    const renderMoveText = (event) => {
        return(
        <AnimatePresence>
            <div className='fight-event w-100'>
                <motion.h2 variants={textVariants} animate="visible" className="roadRage mt-2">{event.sentence}</motion.h2>
            </div>
        </AnimatePresence>
        )
    }
    const secondWind = async () => {
        playWild()
        playerOneHealth.current <= 0 ? playerOneHealth.current += 40 : playerOneHealth.current += 20;
        playerTwoHealth.current <= 0 ? playerTwoHealth.current += 40 : playerTwoHealth.current += 20;

        setTimeout(() => {
            setMatchPhase(phases.matchStart);
        }, 2000)
    }

    const startMatch = async (e) => {
        battle();
        playCrowd()
        setMatchPhase(phases.transition)
        setTimeout(function(){
            playBell();
            setMatchPhase(phases.matchStart)
        }, 900)
    }
    
    return (
        <div className="therest">
            <Container>
                <Header playerOne={playerOne}
                playerTwo={playerTwo}
                matchPhase={matchPhase}
                startMatch={startMatch}
                setPlayerOneName={setPlayerOneName}
                setPlayerTwoName={setPlayerTwoName}
                startMatch={startMatch}
                phases={phases}
                matchType={props.matchType}
                auth={props.auth}
                getUser={props.getUser}
                />
                { matchPhase !== phases.pin ?
                <header
                className={`text-white `} id="match">

                        <h1 className="roadRage">{playerOne.name} vs {playerTwo.name}</h1>
                {/* {!props.queries.isMobile ?
                <div>
                    <Row>
                        <Col>
                        {  playerOne.name.toLowerCase() === 'me' ?
                            <BigHead {...myAvatar}
                            eyebrows={determineEyeBrows(playerOneHealth.current)}
                            eyes={determineEyes(playerOneHealth.current)}
                            mouth={playerOne.mouth}
                            className={`${playerOne.shake} avatar`}
                        />
                    :
                        <BigHead {...playerOne.avatar}
                            eyebrows={determineEyeBrows(playerOneHealth.current)}
                            eyes={determineEyes(playerOneHealth.current)}
                            mouth={playerOne.mouth}
                            className={`${playerOne.shake} avatar`}
                        />
                }
                        </Col>
                        <Col>{(matchPhase === phases.matchStart && startMoves) && renderMoveImg(currentMove.current)}</Col>
                        <Col className='avatarContainer'>
                        <BigHead {...playerTwo.avatar}
                            eyebrows={determineEyeBrows(playerTwoHealth.current)}
                            eyes={determineEyes(playerTwoHealth.current)}
                            mouth={playerTwo.mouth}
                            className={`${playerTwo.shake} avatar`}
                        />
                        </Col>
                        </Row>
                        {(matchPhase === phases.matchStart && startMoves) && renderMoveText(currentMove.current)} 
                        </div>
                        :
                        <div>
                        <Row className='mb-5'>
                        <Col>
                        {  playerOne.name.toLowerCase() === 'me' ?
                            <BigHead {...myAvatar}
                            eyebrows={determineEyeBrows(playerOneHealth.current)}
                            eyes={determineEyes(playerOneHealth.current)}
                            mouth={playerOne.mouth}
                            className={`${playerOne.shake} avatar`}
                            />
                        :
                            <BigHead {...playerOne.avatar}
                                eyebrows={determineEyeBrows(playerOneHealth.current)}
                                eyes={determineEyes(playerOneHealth.current)}
                                mouth={playerOne.mouth}
                                className={`${playerOne.shake} avatar`}
                            />
                        }
                        </Col>
                        <Col />
                        <Col className='avatarContainer'>
                        <BigHead {...playerTwo.avatar}
                            eyebrows={determineEyeBrows(playerTwoHealth.current)}
                            eyes={determineEyes(playerTwoHealth.current)}
                            mouth={playerTwo.mouth}
                            className={`${playerTwo.shake} avatar`}
                        />
                        </Col>
                    </Row>
                    {(matchPhase === phases.matchStart && startMoves) && renderMove(currentMove.current)}
                    </div>
                    }  */}
                    <Row className={`${props.queries.isMobile && 'mb-5'}`}>
                        <Col>
                        {  playerOne.name.toLowerCase() === 'me' ?
                            <BigHead {...myAvatar}
                            eyebrows={determineEyeBrows(playerOneHealth.current)}
                            eyes={determineEyes(playerOneHealth.current)}
                            mouth={playerOne.mouth}
                            className={`${playerOne.shake} avatar`}
                        />
                    :
                        <BigHead {...playerOne.avatar}
                            eyebrows={determineEyeBrows(playerOneHealth.current)}
                            eyes={determineEyes(playerOneHealth.current)}
                            mouth={playerOne.mouth}
                            className={`${playerOne.shake} avatar`}
                        />
                }
                        </Col>
                        {!props.queries.isMobile && <Col />}
                        <Col className='avatarContainer'>
                        <BigHead {...playerTwo.avatar}
                            eyebrows={determineEyeBrows(playerTwoHealth.current)}
                            eyes={determineEyes(playerTwoHealth.current)}
                            mouth={playerTwo.mouth}
                            className={`${playerTwo.shake} avatar`}
                        />
                        </Col>
                        </Row>

                    {(matchPhase === phases.matchStart && startMoves) && renderMove(currentMove.current)}

                </header>
                :
                <div>
                    <Pin playerOne={playerOne} playerTwo={playerTwo} playerOneHealth={playerOneHealth.current} playerTwoHealth={playerTwoHealth.current} event={currentMove.current} secondWind={secondWind} hasSecondWind={hasSecondWind} reset={reset}/>
                </div>
                }
                <div id="bottom"></div>
            </Container>
            <div className={`w-100 healthheight newhealthbars d-flex inline-flex ${matchPhase === phases.winner && 'd-none'}`}>
                <div className={`w-100 ${matchPhase === phases.setup && 'd-none'}`}>
                    <Col>
                        <ProgressBar animated className={`${determineFlash(playerOneHealth.current)} ${playerOne.shake} ${playerOne.pumped} my-1 healthBar ${matchPhase === phases.matchFinish && 'left'}`} style={{ height: '50px', fontSize: '40px'}} variant="danger" now={playerOneHealth.current <= 0 ? 0 : playerOneHealth.current} label={playerOne.name} />
                    </Col>
                    <Col>
                    <ProgressBar animated style={{ height: '50px', fontSize: '40px' }} className={`${determineFlash(playerTwoHealth.current)} ${playerTwo.shake} ${playerTwo.pumped} ${eventArr.length && makeShakeHappen} reverse my-1 healthBar ${matchPhase === phases.matchFinish && 'right'}`} now={playerTwoHealth.current <= 0 ? 0 : playerTwoHealth.current} label={playerTwo.name}  />
                    </Col>
                </div>
            </div>
        </div>
    )
}
