import Parse from 'parse/dist/parse.min.js';

export const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
}

export const filePath = (path) => {
   return require(`${path}`); 
}

export const warrior = filePath('./img/warrior.jpg')
export const wwe = [{
    name: 'Stone Cold',
    pic: filePath('./img/steveaustin.jpg'),
    theme: './sounds/stonecold.mp3',
    finisher: 'Stone Cold Stunner'
},
{
name: 'HHH',
pic: filePath('./img/hhh.jpg'),
theme: './sounds/hhh.mp3',
finisher: 'Pedigree'
},
{
name: 'Kane',
pic: filePath('./img/kane.jpg'),
theme: './sounds/kane.mp3',
finisher: 'Chokeslam from Hell'
},
{
name: 'Undertaker',
pic: filePath('./img/undertaker.jpg'),
theme: './sounds/undertaker.mp3',
finisher: 'Tombstone Piledriver'
}]

export const moves = [{
    name: 'suplex', 
    health: 10,
    pic: filePath('./img/suplex.jpg')
},
{
    name: 'piledriver', 
    health: 12,
    pic: filePath('./img/piledriver.jpg')
},
{
    name: 'Boston Crab',
    health: 10,
    pic: filePath('./img/crab.jpg')
 },
 {
    name: 'Sharpshooter', 
    health: 12,
    pic: filePath('./img/sharpshooter.jpg')
 },
 {
     name: 'DDT',
     health: 10,
     pic: filePath('./img/ddt.jpg')
  },
  {
      name: 'punch', 
      health: 4,
      pic: filePath('./img/punch.jpg')
  },
  {
      name: 'flying elbow drop',
      health: 8,
      pic: filePath('./img/elbow.jpg')
  },
  {
    name: 'gorilla press slam',
    health: 10,
    pic: filePath('./img/gpslam.jpg')
},
{
    name: 'hurricanerana',
    health: 10,
    pic: filePath('./img/hurricanerana.jpg')
},
{
    name: 'dropkick',
    health: 8,
    pic: filePath('./img/dropkick.jpg')
},
{
    name: 'German suplex',
    health: 10,
    pic: filePath('./img/gsuplex.jpg')
},
{
    name: 'powerbomb',
    health: 14,
    pic: filePath('./img/powerebomb.jpg')
},
{
    name: 'spear',
    health: 12,
    pic: filePath('./img/spear.jpg')
},
{
    name: 'chokeslam',
    health: 8,
    pic: filePath('./img/chokeslam.jpg')
},
{
    name: 'facebuster',
    health: 8,
    pic: filePath('./img/facebuster.jpg')
},
{
    name: 'brainbuster',
    health: 10,
    pic: filePath('./img/brainbuster.jpg')
},
{
    name: 'neckbreaker',
    health: 10,
    pic: filePath('./img/neckbreaker.jpg')
},
{
    name: 'backbreaker',
    health: 10,
    pic: filePath('./img/backbreaker.jpg')
},
{
    name: 'powerslam',
    health: 10,
    pic: filePath('./img/powerslam.jpg')
},
{
    name: 'figure four',
    health: 8,
    pic: filePath('./img/figurefour.jpg')
},
{
    name: 'moonsault',
    health: 10,
    pic: filePath('./img/moonsault.jpg')
},
{
    name: '450 splash',
    health: 12,
    pic: filePath('./img/450.jpg')
}];

export const phases = {
    transition: 0,
    setup: 1,
    matchStart: 2,
    pin: 3,
    matchFinish: 4,
    winner: 5
}

export const randEvent = (arr) => {
    const rand = Math.floor(Math.random() * arr.length)
    return arr[rand]
}
export const sounds = {
    crowd: filePath('./sounds/crowd.mp3'),
    bell: filePath('./sounds/bell.mp3'),
    wild: filePath('./sounds/wildcrowd.mp3'),
    austinTheme: filePath('./sounds/stonecold.mp3'),
    kaneTheme: filePath('./sounds/kane.mp3'),
    underTakerTheme: filePath('./sounds/undertaker.mp3'),
    hhhTheme: filePath('./sounds/hhh.mp3'),
    woo: filePath('./sounds/woo.mp3'),
    punch: filePath('./sounds/punch7.mp3'),
    punch1: filePath('./sounds/punch1.mp3'),
    punch2: filePath('./sounds/punch2.mp3'),
    punch3: filePath('./sounds/punch3.mp3'),
    punch4: filePath('./sounds/punch4.mp3'),
    punch5: filePath('./sounds/punch5.mp3'),
    punch6: filePath('./sounds/punch6.mp3'),
    pinTheme: filePath('./sounds/pin.mp3'),
    pumpedSound: filePath('./sounds/pumped.mp3'),
    mainTheme: filePath('./sounds/maintheme.mp3'),
    matchOver: filePath('./sounds/matchover.mp3'),
    one: filePath('./sounds/one.mp3'),
    two: filePath('./sounds/two.mp3'),
    three: filePath('./sounds/three.mp3'),
}

export const soundsArr = {
    crowd: filePath('./sounds/crowd.mp3'),
    bell: filePath('./sounds/bell.mp3'),
    wild: filePath('./sounds/wildcrowd.mp3'),
    austinTheme: filePath('./sounds/stonecold.mp3'),
    kaneTheme: filePath('./sounds/kane.mp3'),
    underTakerTheme: filePath('./sounds/undertaker.mp3'),
    hhhTheme: filePath('./sounds/hhh.mp3'),
    woo: filePath('./sounds/woo.mp3'),
    punch: filePath('./sounds/punch7.mp3'),
    punch1: filePath('./sounds/punch1.mp3'),
    punch2: filePath('./sounds/punch2.mp3'),
    punch3: filePath('./sounds/punch3.mp3'),
    punch4: filePath('./sounds/punch4.mp3'),
    punch5: filePath('./sounds/punch5.mp3'),
    punch6: filePath('./sounds/punch6.mp3'),
    pinTheme: filePath('./sounds/pin.mp3'),
    pumpedSound: filePath('./sounds/pumped.mp3'),
    mainTheme: filePath('./sounds/maintheme.mp3'),
    matchOver: filePath('./sounds/matchover.mp3'),
    one: filePath('./sounds/one.mp3'),
    two: filePath('./sounds/two.mp3'),
    three: filePath('./sounds/three.mp3'),
}
export const determineFlash = (health) => {
    if (health <= 40 && health > 20 ) {
        return 'flash'
    } else if (health <= 20 && health > 10) {
        return 'flashquick'
    } else if (health <= 10) {
        return 'flashquickest'
    } else {
        return ''
    }
}
export const determineMouth = (health) => {
    if (health <= 40 && health > 20 ) {
        return 'open'
    } else if (health <= 20) {
        return 'sad'
    } else {
        return 'serious'
    }
}
export const determineEyes = (health) => {
    if (health <= 60 && health > 30 ) {
        return 'leftTwitch'
    } else if (health <= 30 && health > 10) {
        return 'squint'
    } else if (health <= 10) {
        return 'dizzy'
    } else {
        return 'normal'
    }
}
export const determineEyeBrows = (health) => {
    if (health <= 40 && health > 20 ) {
        return 'raised'
    } else if (health <= 20) {
        return 'concerned'
    } else {
        return 'serious'
    }
}
export const determineShake = (damage) => {
    if (damage < 6 && damage > 0){
        return "shake"
    }
    if (damage >= 6 && damage <= 10 ){
        return "bigShake"
    }
    if (damage >= 12){
        return "hugeShake"
    }
}
export const verbs = ['hits', 'pummels', 'nails', 'pounds', 'destroys', 'slams', 'smashes', 'bashes', 'busts']
export const sizes = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
};

export const accessories = ['none', 'roundGlasses', 'tinyGlasses', 'shades']
export const bodies = ['chest', 'breasts']
export const clothing = ['naked', 'shirt', 'dressShirt', 'vneck', 'tankTop', 'dress']
export const clothingColors = ['white', 'blue', 'black', 'green', 'red']
export const eyebrows = ['raised', 'leftLowered', 'serious', 'angry', 'concerned']
export const eyes = ['normal', 'leftTwitch', 'happy', 'content', 'squint', 'simple', 'dizzy', 'wink', 'heart']
export const facialHairs = ['none', 'stubble', 'mediumBeard']
export const graphics = ['none', 'redwood', 'gatsby', 'vue', 'react', 'graphQL']
export const hairs = ['none', 'long', 'bun', 'short', 'pixie', 'balding', 'buzz', 'afro', 'bob']
export const hairColors = ['blonde', 'orange', 'black', 'white', 'brown', 'blue', 'pink']
export const hats = ['none', 'beanie', 'turban']
export const hatColors = ['white', 'blue', 'black', 'green', 'red']
export const lipColors = ['red', 'purple', 'pink', 'turqoise', 'green']
export const mouths = ['grin', 'sad', 'openSmile', 'lips', 'open', 'serious', 'tongue']
export const skinTones = ['light', 'yellow', 'brown', 'dark', 'red', 'black']

export const createNewUserInfo = async (name, userId) => {
    const defaultInfo = {
    name: '',
    wins: 0,
    losses: 0,
    score: 0,
    taunt: '',
    theme: sounds.matchOver,
    avatar: {
        accessory: "none",
        body: "chest",
        circleColor: "blue",
        clothing: "vneck",
        clothingColor: "green",
        eyebrows: "serious",
        eyes: "normal",
        facialHair: "stubble",
        graphic: "none",
        hair: "none",
        hairColor: "black",
        hat: "none",
        hatColor: "green",
        lashes: "false",
        lipColor: "purple",
        mask: false,
        mouth: "serious",
        skinTone: "brown",
    },
    fights: [],
    slogan: 'I hate you',
    moveSet: moves,
    avatarOptions: {
        accessories: ['none', 'roundGlasses', 'shades'],
        bodies: ['chest', 'breasts'],
        clothing: ['naked', 'shirt', 'tankTop', 'dress'],
        clothingColors: ['white', 'blue', 'black'],
        eyebrows: ['serious', 'angry', 'concerned'],
        eyes: ['normal', 'leftTwitch', 'happy', 'content', 'squint', 'simple', 'dizzy', 'wink', 'heart'],
        facialHairs: ['none', 'stubble', 'mediumBeard'],
        graphics: ['none', 'redwood', 'gatsby', 'vue', 'react', 'graphQL'],
        hairs: ['none', 'long', 'bun', 'short', 'pixie', 'buzz'],
        hairColors: ['blonde', 'orange', 'black', 'white', 'brown'],
        hats: ['none', 'beanie', 'turban'],
        hatColors: ['white', 'blue', 'black'],
        lipColors: ['red', 'purple', 'pink', 'turqoise', 'green'],
        mouths: ['grin', 'sad', 'openSmile', 'lips', 'open', 'serious', 'tongue'],
        skinTones: ['light', 'yellow', 'brown', 'dark', 'red', 'black']
    },
    friends: []
}
    let newUser = new Parse.Object('UserInfo')
    newUser.set('name', name)
    newUser.set('wins', defaultInfo.wins)
    newUser.set('losses', defaultInfo.losses)
    newUser.set('score', defaultInfo.score)
    newUser.set('user', userId)
    newUser.set('taunt', defaultInfo.taunt)
    newUser.set('theme', defaultInfo.theme)
    newUser.set('avatar', defaultInfo.avatar)
    newUser.set('fights', defaultInfo.fights)
    newUser.set('slogan', defaultInfo.slogan)
    newUser.set('moveset', defaultInfo.moveSet)
    newUser.set('avatarOptions', defaultInfo.avatarOptions)
    try {
        await newUser.save();
        // Success
        alert('Success! User info created!');

        return defaultInfo;
      } catch (error) {
        // Error can be caused by lack of Internet connection
        alert(`Error! ${error.message}`);
        return {};
      };


};

export const getUserAvatar = async function () {
    // Reading parse objects is done by using Parse.Query
    const newAvatar = await new Parse.Object('avatar');
    const currentUser = await Parse.User.current();
    if (currentUser){
    newAvatar.set('user', currentUser.id);
    try {
      await newAvatar.save()
      const parseQuery = await new Parse.Query('avatar');
      parseQuery.equalTo('user', `${currentUser.id}`)
      const userAvatar = await parseQuery.first();
      // Be aware that empty or invalid queries return as an empty array
      // Set results to state variable
      return userAvatar;
    } catch (error) {
      // Error can be caused by lack of Internet connection
      alert(`Error! ${error.message}`);
      return {};
    }
} else {
    return randomAvatar()
}
  };

  export const checkForCurrentChallenge = async (challengee) => {
    try {
        // create a new Parse Object instance
        const you = await getMyInfo()
        let fightQuery = new Parse.Query('Fight')
        fightQuery.contains('challengerName', you.attributes.name)
        fightQuery.contains('challengeeName', challengee.attributes.name)

        const res = await fightQuery.find()
        if(res){
            return res[0].attributes.status
        }


      } catch (error) {
        console.log('Error finding challengers: ', error);
      }
  }
  export const getYourFights = async (user) => {
    try {
        // create a new Parse Object instance
        let fightQuery = new Parse.Query('Fight')
        fightQuery.contains('challengerName', user.attributes.name)

        const res = await fightQuery.find()

        return res

      } catch (error) {
        console.log('Error finding challengers: ', error);
      }
  }
  export const getMyInfo = async() => {
    const currentUser = await Parse.User.current();
    if (currentUser){
    const youQuery = new Parse.Query('UserInfo');
    youQuery.contains('user', currentUser.id.toString())
    let you = await youQuery.find()
    
    return you[0]
    } else {
        return null
    }
  }
  export const createFight = async(challengee) => {
    try {
        // create a new Parse Object instance
        const you = await getMyInfo()
        let fight = {
            challenger: you.id,
            challengee: challengee.id,
            accepted: false
        }
        let newFight = new Parse.Object('Fight')
        newFight.set('challengerName', you.attributes.name)
        newFight.set('challengeeName', challengee.attributes.name)
        newFight.set('status', 'created')
        newFight.set('events', ['nothing', 'yet'])

        let newYou = new Parse.Object('UserInfo')
        newYou.set('objectId', you.id)
        newYou.set('fights', [fight])
        let newOpp = new Parse.Object('UserInfo')
        newOpp.set('objectId', challengee.id)
        newOpp.set('fights', [fight])

        await newYou.save();
        await newOpp.save();
        await newFight.save()
        alert('Fight saved!');

      } catch (error) {
        console.log('Error saving new person: ', error);
      }
  }

  export const randomAvatar = () => {
      const avatar = {
    accessory: randEvent(accessories),
    body: randEvent(bodies),
    clothing: randEvent(clothing),
    clothingColor: randEvent(clothingColors),
    eyebrows: randEvent(eyebrows),
    eyes: randEvent(eyes),
    facialHair: randEvent(facialHairs),
    graphic: randEvent(graphics),
    hair: randEvent(hairs),
    hairColor: randEvent(hairColors),
    hat: randEvent(hats),
    hatColor: randEvent(hatColors),
    lipColor: randEvent(lipColors),
    mask: false,
    mouth: randEvent(mouths),
    skinTone: randEvent(skinTones)
  }
  return avatar;
}