import React from 'react'

export const LoadedApp = () => {
  return (
    <div>
        <div className="image-grid">
            <img src='./img/suplex.jpg' alt="Example 1" />
            <img src="https://via.placeholder.com/150" alt="Example 2" />
        </div>
        <div className="audio-player">
            <audio controls>
            <source src="https://file-examples.com/wp-content/uploads/2017/11/file_example_MP3_700KB.mp3" type="audio/mpeg"></source>
            </audio>
        </div>
    </div>
  );
}
