import React, { useReducer } from 'react'
import { PreloadMedia, MediaType } from 'react-preload-media';
import { LoadedApp } from './PreloadedAppTest'
const media = [
    { type: MediaType.Image, url: './img/suplex.jpg' },
    { type: MediaType.Image, url: './img/piledriver.jpg' },
    { type: MediaType.Image, url: './img/crab.jpg' },
    { type: MediaType.Image, url: './img/sharpshooter.jpg' },
    { type: MediaType.Image, url: './img/ddt.jpg' },
    { type: MediaType.Image, url: './img/elbow.jpg' },
    { type: MediaType.Image, url: './img/punch.jpg' },
    { type: MediaType.Image, url: './img/gpslam.jpg' },
    { type: MediaType.Image, url: './img/hurricanerana.jpg' },
    { type: MediaType.Image, url: './img/dropkick.jpg' },
    { type: MediaType.Image, url: './img/gsuplex.jpg' },
    { type: MediaType.Image, url: './img/powerebomb.jpg'},
    { type: MediaType.Image, url: './img/chokeslam.jpg' },
    { type: MediaType.Image, url: './img/facebuster.jpg' },
    { type: MediaType.Image, url: './img/brainbuster.jpg' },
    { type: MediaType.Image, url: './img/neckbreaker.jpg' },
    { type: MediaType.Image, url: './img/backbreaker.jpg' },
    { type: MediaType.Image, url: './img/powerslam.jpg' },
    { type: MediaType.Image, url: './img/figurefour.jpg' },
    { type: MediaType.Image, url: './img/moonsault.jpg' },
    { type: MediaType.Image, url: './img/450.jpg' },
    { type: MediaType.Image, url: 'https://via.placeholder.com/300' },
    { type: MediaType.Audio, url: 'https://file-examples.com/wp-content/uploads/2017/11/file_example_MP3_700KB.mp3' },
    { type: MediaType.Audio, url: './sounds/crowd.mp3' },
    { type: MediaType.Audio, url: './sounds/bell.mp3' },
    { type: MediaType.Audio, url: './sounds/wildcrowd.mp3' },
    { type: MediaType.Audio, url: './sounds/stonecold.mp3' },
    { type: MediaType.Audio, url: './sounds/kane.mp3' },
    { type: MediaType.Audio, url: './sounds/undertaker.mp3' },
    { type: MediaType.Audio, url: './sounds/hhh.mp3' },
    { type: MediaType.Audio, url: './sounds/woo.mp3' },
    { type: MediaType.Audio, url: './sounds/punch7.mp3' },
    { type: MediaType.Audio, url: './sounds/punch1.mp3' },
    { type: MediaType.Audio, url: './sounds/punch2.mp3' },
    { type: MediaType.Audio, url: './sounds/punch3.mp3' },
    { type: MediaType.Audio, url: './sounds/punch4.mp3' },
    { type: MediaType.Audio, url: './sounds/punch5.mp3' },
    { type: MediaType.Audio, url: './sounds/punch6.mp3' },
    { type: MediaType.Audio, url: './sounds/pin.mp3' },
    { type: MediaType.Audio, url: './sounds/pumped.mp3' },
    { type: MediaType.Audio, url: './sounds/maintheme.mp3' },
    { type: MediaType.Audio, url: './sounds/matchover.mp3' },
    { type: MediaType.Audio, url: './sounds/one.mp3' },
    { type: MediaType.Audio, url: './sounds/two.mp3' },
    { type: MediaType.Audio, url: './sounds/three.mp3' },
  ];

export const PreloadTest = () => {
    const [isLoaded, loaded] = useReducer(() => true, false);

    return (
      <div class="container">
        <PreloadMedia media={media} onFinished={() => loaded()}>
          <div className="preloading-container">
            <h1>Preloading</h1>      
          </div>
        </PreloadMedia>
        { isLoaded ? <LoadedApp /> : <></> }
      </div>
    )
  }
