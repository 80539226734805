import { randEvent, shuffle, warrior, verbs, moves, getMyInfo } from '../helpers'
import Parse from 'parse/dist/parse.min.js';

export const createFight = async(challengee) => {
    try {
        // create a new Parse Object instance
        const you = await getMyInfo()
        let fight = {
            challenger: you.id,
            challengee: challengee.id,
            accepted: false
        }
        let events = await matchEvents(you.attributes, 100, challengee.attributes, 100)
        let newFight = new Parse.Object('Fight')
        newFight.set('challenger', you.attributes)
        newFight.set('challengee', challengee.attributes)
        newFight.set('challengerName', you.attributes.name)
        newFight.set('challengeeName', challengee.attributes.name)
        newFight.set('status', 'created')
        newFight.set('events', events)

        let newYou = new Parse.Object('UserInfo')
        newYou.set('objectId', you.id)
        newYou.set('fights', [fight])
        let newOpp = new Parse.Object('UserInfo')
        newOpp.set('objectId', challengee.id)
        newOpp.set('fights', [fight])


        await newYou.save();
        await newOpp.save();
        await newFight.save()
        alert('Fight saved!');

      } catch (error) {
        console.log('Error starting fight: ', error);
      }
  }
export const matchEvents = async (playerOne, playerOneHealth, playerTwo, playerTwoHealth) => {
    const wrestlers = [playerOne, playerTwo];
    const eventArray = []
    let pOneHealth = playerOneHealth;
    let pTwoHealth = playerTwoHealth;
    const wrestleLoop = () => {
        while (pOneHealth > 0 && pTwoHealth > 0) {
        const move = randEvent(moves);
        const wrassler = shuffle(wrestlers);
        const verb = randEvent(verbs);
        const pumped = (Math.random() * 100 < 5);

        const sentence = !pumped ? `${wrassler[0].name} ${verb} ${wrassler[1].name} with a ${move.name}` : `${wrassler[0].name} gets pumped up!`
        if (wrassler[0] === playerOne) {
            !pumped ? pTwoHealth -= move.health : pTwoHealth += move.health;

        } else {
            !pumped ? pOneHealth -= move.health : pOneHealth += move.health;
        }
        const newEvent = {
            sentence: sentence,
            img: !pumped ? move.pic : warrior,
            recipient: wrassler[1],
            attacker: wrassler[0],
            health: pumped ? -move.health : move.health,
            playerOneHealth: pOneHealth,
            playerTwoHealth: pTwoHealth,
            type: 'move'
        }
        eventArray.push(newEvent)
    }
    if (pOneHealth >= 0 || pTwoHealth >= 0) {
        const pinner = pOneHealth > pTwoHealth ? playerOne.name : playerTwo.name
        const pinnedGuy = pOneHealth < pTwoHealth ? playerOne.name : playerTwo.name
        const chance = Math.floor(Math.random() * 2)
        if(chance === 1) {
            eventArray.push({
                sentence: `${pinnedGuy} gets a second wind!`,
                attacker: pinner,
                recipient: pinnedGuy,
                type: 'secondWind'
            })
            if(pOneHealth > pTwoHealth) {
                pOneHealth += 20
                pTwoHealth += 40
            } else {
                pOneHealth += 40
                pTwoHealth += 20
            }
            wrestleLoop();
        } else {
            eventArray.push({
                sentence: `${pinner} wins!`,
                attacker: pinner,
                recipient: pinnedGuy,
                type: 'pin'
        })
        }
    }
}
    wrestleLoop()

    return eventArray
}

export const determineSecondWind = (playerOneHealth, playerTwoHealth) => {

}