import React, {useEffect} from 'react'
import { Main } from './Main'
import { Redirect } from 'react-router-dom';

export const OfficialMatch = (props) => {

    return (
        <div>
            <Main 
            queries={props.queries} 
            user={props.user} 
            avatar={props.avatar}
            playerOneProp={props.playerOne}
            playerTwoProp={props.playerTwo}
            matchEvents={props.matchEvents}
            matchType="official"/>
        </div>
    )
}
