import './App.css';
import './style.css';
import './menuStyles.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, useContext, useReducer } from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import Parse from 'parse/dist/parse.min.js';
import { useMediaQuery } from 'react-responsive'
import { authContext } from './context'
import { Loading } from './components/Loading';
import Loadable from 'react-loadable';
import { QuickMatch } from './QuickMatch';
import { PreloadMedia, MediaType } from 'react-preload-media';

// require('dotenv').config()
Parse.initialize(process.env.REACT_APP_PARSE_APPLICATION_ID, process.env.REACT_APP_PARSE_JAVASCRIPT_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_HOST_URL;

const media = [
  { type: MediaType.Audio, url: './sounds/bell.mp3' },
  { type: MediaType.Audio, url: './sounds/bigpop.mp3' },
  { type: MediaType.Audio, url: './sounds/crowd.mp3' },
  { type: MediaType.Audio, url: './sounds/hhh.mp3' },
  { type: MediaType.Audio, url: './sounds/kane.mp3' },
  { type: MediaType.Audio, url: './sounds/maintheme.mp3' },
  { type: MediaType.Audio, url: './sounds/matchover.mp3' },
  { type: MediaType.Audio, url: '../../../Downloads/backing.mp3' },
];
function App() {
  
  const queries = {
    isDesktopOrLaptop: useMediaQuery({ query: '(min-width: 1224px)' }),
    isBigScreen: useMediaQuery({ query: '(min-width: 1824px)' }),
    isTabletOrMobile: useMediaQuery({ query: '(max-width: 1224px)' }),
    isMobile: useMediaQuery({ query: '(max-width: 400px)' }),
    isPortrait: useMediaQuery({ query: '(orientation: portrait)' }),
    isRetina: useMediaQuery({ query: '(min-resolution: 2dppx)' }),
  }

  const [isLoaded, loaded] = useReducer(() => true, false);
  const { loggedIn, setLoggedIn, user, setUser, avatar, setAvatar } = useContext(authContext)
useEffect(() => {
  setInterval(console.log(isLoaded), 500)
})
  return (
      <div className="App">
      <PreloadMedia media={media} onFinished={() => loaded()}>
        <Loading />
      </PreloadMedia>
  { isLoaded ? <QuickMatch queries={queries} user={user} avatar={avatar}/> : <></> }

      </div>
  );
}

export default App;
