import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Card, CardTitle, CardText, CardBody, CardImg, Button } from 'reactstrap';
import { BigHead } from '@bigheads/core'
import { checkForCurrentChallenge } from '../helpers'
import { createFight } from '../helpers/fighthelpers'
export const Friend = (props) => {
    const [fightStatus, setFightStatus] = useState('')
    const challengePlayer = async (challengee) => {
        await createFight(challengee)
        checkForChallenge()
    }
    const checkForChallenge = async () => {
        const res = await checkForCurrentChallenge(props.user)
        setFightStatus(res)
    }
    useEffect(() => {
        checkForChallenge()
    }, [])
    return(
    <div>
        <Container>
            <Row className='w-100 mb-3'>
                <Card style={{ borderRadius: '5px' }}>
                <CardBody className="p-1">
                    <div className={`${!props.queries.isMobile ? 'd-flex' : ''} text-black`}>

                    <div className="flex-grow-1 ms-3">
                        <CardTitle><h4 className='mb-0'>{props.user.attributes.name.toUpperCase()}</h4></CardTitle>
                        <Row className="d-flex justify-content-center rounded-2 mb-0 pr-0"
                        style={{ backgroundColor: '#efefef' }}>
                                <div style={{ height: '6vh'}} className='px-0'>
                                    <BigHead {...props.user.attributes.avatar} className='profileAvatar' style={{ height: '100%'}}/>
                                </div>

                        <Col className='px-0 my-auto'>
                            <p className="small text-muted mb-1">Wins</p>
                            <p className="mb-0 small">{props.user.attributes.wins}</p>
                        </Col>
                        <Col className="px-0 my-auto">
                            <p className="small text-muted mb-1">Losses</p>
                            <p className="mb-0 small">{props.user.attributes.losses}</p>
                        </Col>
                        <Col className='px-0 my-auto'>
                            <p className="small text-muted mb-1">Friends</p>
                            <p className="mb-0 small">{props.user.attributes.friends && props.user.attributes.friends.length}</p>
                        </Col>
                        <Col className='px-0 my-auto'>
                            {fightStatus === 'created' ? 
                                <Button color='dark'>REQUESTED</Button>
                                : 
                                <Button color='success' onClick={() => challengePlayer(props.user)}>CHALLENGE</Button>
                                }
                        </Col>
                        </Row>
                    </div>
                    </div>
                </CardBody>
                </Card>
            </Row>
        </Container>

</div>
);
}
