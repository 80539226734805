import React, { useState, useContext } from 'react';
import Parse from 'parse/dist/parse.min.js';
import '../../App.css';
import { authContext } from '../../context'
import { Button, Divider, Input, Row, Col } from 'reactstrap';
import { getUserAvatar, createNewUserInfo, getMyInfo } from '../../helpers'

export const Registration = (props) => {

    const { loggedIn, setLoggedIn, user, setUser, avatar, setAvatar } = useContext(authContext)
  // State variables
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const doUserRegistration = async function () {
    // Note that these values come from state variables that we've declared before
    const usernameValue = username;
    const emailValue = email;
    const passwordValue = password;
    try {
      // Since the signUp method returns a Promise, we need to call it using await
      const createdUser = await Parse.User.signUp(usernameValue, passwordValue, {
          wins: 0,
          losses: 0,
          score: 0,
          rank: 0,
          email: emailValue
      });
      alert(
        `Success! User ${createdUser.getUsername()} was successfully created!`
      );
      await setLoggedIn(true)

      const currentUser = await Parse.User.current();
      await createNewUserInfo(currentUser.getUsername(), currentUser.id)
      const newUserInfo = await getMyInfo()
      await setUser(newUserInfo)
      await setAvatar(newUserInfo.attributes.avatar)
      props.reset();
      return true;
    } catch (error) {
      // signUp can fail if any parameter is blank or failed an uniqueness check on the server
      alert(`Error! ${error}`);
      return false;
    }
  };

  const setUserAvatar = async function () {
    const userAvatar = await getUserAvatar()
    setAvatar(userAvatar)
  };

  return (
    <div>
      <div className="header">
        <h2 className="roadRage">{'User Registration'}</h2>
      </div>
      {!loggedIn &&
      <div className="container">
        <Col className="form-group">
            <Row>
                <label for="username" class="roadRage">Username:</label>
                <Input
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    placeholder="Username"
                    name="username"
                    className="form_input mb-3"
                />
            </Row>
            <Row>
                <label for="email" class="roadRage">Email:</label>
                <Input
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder="Email"
                    name="email"
                    className="form_input mb-3"
                />
            </Row>
             <Row>
            <label for="password" class="roadRage">Password:</label>
            <Input
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Password"
                type="password"
                className="form_input"
            />
            </Row>
        </Col>

        <div>
          <button color='primary mb-4 mt-4' onClick={() => doUserRegistration()}>Sign Up</button>
        </div>
      </div>
    }
    </div>
  );
};