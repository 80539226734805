import React, { useContext, useState, useEffect } from 'react'
import { motion, useCycle, AnimatePresence } from "framer-motion"
import { authContext } from '../context'
import { MenuButton } from './MenuButton'
import { getCurrentUser, doUserLogOut } from './auth/UserLogIn'
import { Link } from 'react-router-dom'

export const Menu = (props) => {
    const [open, cycleOpen] = useCycle(false, true);
    const [width, setWidth] = useState("30vw")
    const menuLogOut = async() => {
        await doUserLogOut()
        setLoggedIn(false)
        cycleOpen();
    }
    const { loggedIn, setLoggedIn } = useContext(authContext)
    const setSize = () => {
        if (props.queries.isDesktopOrLaptop || props.queries.isBigScreen) {
            setWidth("30vw")
        } else if (props.queries.isTabletOrMobile){
            setWidth("100vw")
        }
    }
    useEffect(setSize, [width])
    // addEventListener('resize', setSize());

    const sideVariants = {
        closed: {
          transition: {
            staggerChildren: 0.2,
            staggerDirection: -1
          }
        },
        open: {
          transition: {
            staggerChildren: 0.2,
            staggerDirection: 1
          }
        }
      };
      const itemVariants = {
        closed: {
          opacity: 0,
          transition: {
            delay: 0.2, duration: 0.1
          }
        },
        open: { opacity: 1 }
      };
    return (
        <div className="menu align-items-right">
            <AnimatePresence>
            {open && (
            <motion.aside 
            className="menu"
                initial={{ width: 0 }} 
                animate={{ width: width }}
                exit={{
                    width: 0,
                    transition: { delay: 0.2, duration: 0.3 }
                  }}>
            <motion.div 
            className="menu"
                className="container"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
            >
                <motion.div key={1} to='/' whileHover={{ scale: 1.1 }} variants={itemVariants} onClick={cycleOpen}>
                   <Link className="menuLink" to='quickmatch'>Quick Match</Link>
                </motion.div>
                {loggedIn &&
                <div>
                <motion.div key={2} whileHover={{ scale: 1.1 }} variants={itemVariants} onClick={cycleOpen}>
                    <Link className="menuLink" to='/officialmatch'>Official Match</Link>
                </motion.div>
                <motion.div key={3} whileHover={{ scale: 1.1 }} variants={itemVariants} onClick={cycleOpen}>
                    <Link className="menuLink" to='/profile'>My Profile</Link>
                </motion.div>
                <motion.div key={8} whileHover={{ scale: 1.1 }} variants={itemVariants} onClick={cycleOpen}>
                    <Link className="menuLink" to='/findfriends'>Find Friends</Link>
                </motion.div>
                </div>
                }
                <motion.div key={4} whileHover={{ scale: 1.1 }} variants={itemVariants} onClick={cycleOpen}>
                    <Link className="menuLink" to='/leaderboard'>Leaderboard</Link>
                </motion.div>
                {loggedIn &&
                <motion.div key={5} href={'#'} whileHover={{ scale: 1.1 }} variants={itemVariants} onClick={menuLogOut}>
                    <Link className="menuLink" to='/'>Log out</Link>
                </motion.div>
                }
                {!loggedIn &&
                <div>
                <motion.div key={6} href={'#'} whileHover={{ scale: 1.1 }} variants={itemVariants} onClick={cycleOpen}>
                    <Link className="menuLink" to='/login'>Log in</Link>
                </motion.div>
                <motion.div key={7} href={'#'} whileHover={{ scale: 1.1 }} variants={itemVariants} onClick={cycleOpen}>
                    <Link className="menuLink" to='/register'>Register</Link>
                </motion.div>
                </div>
                }
            </motion.div>
            </motion.aside>
            )}
            </AnimatePresence>
            <div className="btn-container text-white">
            <MenuButton   
                className="m-3 menu-button"     
                isOpen={open}
                onClick={cycleOpen}
            />
            </div>
        </div>
    )
}
