import React, { useState, useEffect, useContext } from 'react'
import Parse from 'parse/dist/parse.min.js';
import { BigHead } from '@bigheads/core'
import { Col, Container, Row, Card, CardTitle, CardText, CardBody, CardImg, Button } from 'reactstrap';
import { Friend } from './Friend'
import { getMyInfo } from '../helpers'
import { authContext } from '../context';
export const FindFriends = (props) => {
    const [users, setUsers] = useState([])
    const { user } = useContext(authContext)
    const getUsers = async () => {
        const query = new Parse.Query('UserInfo')
        const userList = await query.find()
        const filtered = userList.filter(x => x.id !== user.id)
        await setUsers(filtered)
    }
    useEffect(() => {
        getUsers()
    }, [])
    return (
        <div className="text-white text-center">
            <h1>FIND FRIENDS</h1>
            <div className='mt-5'>
            { users && 
                users.map(x => 
                    <Row>
                        <Friend user={x} key={x.id} queries={props.queries}/>
                    </Row>
                    )}
            </div>
        </div>
    )
}
